/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/swiper@9.4.1/swiper-bundle.min.js
 * - /npm/inputmask@5.0.8/dist/inputmask.min.js
 * - /npm/aos@3.0.0-beta.6/dist/aos.min.js
 * - /npm/jquery@3.7.0/dist/jquery.min.js
 * - /npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
